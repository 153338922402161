import $ from "jquery";
import "slick-carousel";
import Lenis from "@studio-freight/lenis";

// Helpers
function throttle(cb, delay) {
  let wait = false;

  return (...args) => {
    if (wait) {
      return;
    }

    cb(...args);
    wait = true;
    setTimeout(() => {
      wait = false;
    }, delay);
  };
}

// Sliders
$(".research-slider__slider").slick({
  arrows: true,
  dots: true,
  asNavFor: ".research-slider__taglines",
  autoplay: true,
  autoplaySpeed: 5000,
});

$(".research-slider__taglines").slick({
  arrows: false,
  dots: false,
  asNavFor: ".research-slider__slider",
});

$(".steps__slider").slick({
  arrows: true,
  dots: false,
});

// Smooth scroll link click
$(".header__flex a").on("click", function (event) {
  // target all links
  if (this.hash !== "") {
    event.preventDefault();
    const hash = this.hash;

    $("html, body").animate(
      {
        scrollTop: $(hash).offset().top,
      },
      800
    );
  }
});

// Smooth scrolling
const lenis = new Lenis();

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

// Header nav
$(window).on(
  "scroll",
  throttle(function () {
    setActiveNav();
  }, 100)
);

function getOffsetTop(element) {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

function setActiveNav() {
  const scrollPos = $(window).scrollTop() + 250;
  const anchors = document.querySelectorAll(
    ".header__flex .header-anchor-section"
  );
  const els = [];

  anchors.forEach((anchor) => {
    const section = document.querySelector(anchor.getAttribute("href"));
    els.push({ top: getOffsetTop(section), anchor });
  });

  els.forEach((el) => {
    if (el.top < scrollPos) {
      el.anchor.parentElement.classList.add("header__link--active");
    } else {
      el.anchor.parentElement.classList.remove("header__link--active");
    }
  });

  $(".header__link--last").removeClass("header__link--last");
  const actives = document.querySelectorAll(".header__link--active");
  const lastActive = actives[actives.length - 1];
  if (lastActive) lastActive.classList.add("header__link--last");
}

setActiveNav();

document.addEventListener("DOMContentLoaded", function () {
  const colorDiv = document.querySelector(".color-fade-section__color");
  const breakthrough = document.querySelector(".breakthrough");

  const startColor = [0, 62, 53]; // RGB for #392e87
  const endColor = [240, 237, 229]; // RGB for #fff

  const delayFactor = 0.3; // Adjust this value as desired

  function setFade() {
    const breakthroughTop = breakthrough.getBoundingClientRect().top;
    const breakthroughHeight = breakthrough.offsetHeight;

    if (
      breakthroughTop >= window.innerHeight ||
      breakthroughTop + breakthroughHeight <= 0
    ) {
      // The .breakthrough element is not in view
      return;
    }

    // Calculate fade based on the relative position of the .breakthrough within the viewport
    let fadeRatio =
      1 -
      (breakthroughTop + breakthroughHeight) /
        (window.innerHeight + breakthroughHeight);

    // Adjust fadeRatio based on delay factor
    fadeRatio = (fadeRatio - delayFactor) / (1 - delayFactor);
    fadeRatio = Math.max(fadeRatio, 0); // Ensure it doesn't go negative

    const newColor = startColor.map((start, index) =>
      Math.round(start + fadeRatio * (endColor[index] - start))
    );
    colorDiv.style.backgroundColor = `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
  }

  setFade(); // Set the fade on initial page load
  window.addEventListener("scroll", setFade); // Adjust fade on scroll
});

$(document).ready(function () {
  // For onload animations
  $("body").addClass("ready");

  // Scroll based animations
  var els = $(".on-scroll");

  function scrollAnimations() {
    // Scroll position
    var pos = $(window).scrollTop() + $(window).height();

    // fade in
    els.each(function (i) {
      var el = els.eq(i);
      var elPos = el.offset().top;

      if (pos > elPos) el.addClass("active");
    });
  }

  scrollAnimations();

  if (els.length) {
    $(window).on(
      "scroll",
      throttle(function () {
        scrollAnimations();
      }, 100)
    );
  }
});

// Click event for .infra-building__layer
$(".infra-building__layer").on("click", function (e) {
  e.preventDefault();

  var index = $(this).index();
  var infraElement = $(".infra");

  // Remove old active classes
  if (infraElement.hasClass("infra--active-" + index)) {
    infraElement.removeClass(function (index, className) {
      return (className.match(/(^|\s)infra--active-\S+/g) || []).join(" ");
    });
    infraElement.removeClass("infra--activated");
  } else {
    infraElement.removeClass(function (index, className) {
      return (className.match(/(^|\s)infra--active-\S+/g) || []).join(" ");
    });

    infraElement.addClass("infra--activated infra--active-" + index);
  }

  // Update mobile floor selector
  $(".mobile-floor-selector__floor").removeClass(
    "mobile-floor-selector__floor--active"
  );
  $(".mobile-floor-selector__floor")
    .eq(index - 1)
    .addClass("mobile-floor-selector__floor--active");
});

// Click event for .mobile-floor-selector__floor
$(".mobile-floor-selector__floor").on("click", function () {
  // Remove the active class from all floors
  $(".mobile-floor-selector__floor").removeClass(
    "mobile-floor-selector__floor--active"
  );

  // Add the active class to the clicked floor
  $(this).addClass("mobile-floor-selector__floor--active");

  // Update .infra element
  var infraElement = $(".infra");
  var index = $(this).index();

  // Remove old active classes
  if (infraElement.hasClass("infra--active-" + index)) {
    infraElement.removeClass(function (index, className) {
      return (className.match(/(^|\s)infra--active-\S+/g) || []).join(" ");
    });
    infraElement.removeClass("infra--activated");
  } else {
    infraElement.removeClass(function (index, className) {
      return (className.match(/(^|\s)infra--active-\S+/g) || []).join(" ");
    });
  }

  infraElement.addClass("infra--activated");
  infraElement.addClass("infra--active-" + (Number(index) + 1));
});

$(document).ready(function () {
  // Function to update map points and numbers based on active toggles
  function updateMap() {
    if ($(".map-toggle--active").length === 0) {
      // If no toggle is active, hide all map numbers and show all map points
      $(".map-num").hide();
      $(".map-point").show();
    } else {
      // Hide all points and numbers
      $(".map-point").hide();
      $(".map-num").hide();

      // Show only the active map points and numbers
      $(".map-toggle--active").each(function () {
        var colorClass = $(this)
          .find(".color")
          .attr("class")
          .split(" ")
          .filter(function (cls) {
            return cls.startsWith("color--");
          })[0]
          .replace("color--", "");

        // Show map points and numbers with matching color classes
        $(".map-point--" + colorClass).show();
        $(".map-num__circle--" + colorClass)
          .closest(".map-num")
          .show();
      });
    }
  }

  // Click event for toggles
  $(".map-toggle").click(function (e) {
    e.preventDefault();

    // Check if the clicked toggle is already active
    if ($(this).hasClass("map-toggle--active")) {
      // If it is already active, remove the active class to unselect
      $(this).removeClass("map-toggle--active");
    } else {
      // If it's not active, remove the active class from all toggles and set it to the clicked one
      $(".map-toggle").removeClass("map-toggle--active");
      $(this).addClass("map-toggle--active");
    }

    // Update the map based on active toggles
    updateMap();
  });

  // Initialize map display on load
  updateMap();
});

const footerYear = document.querySelector(".footer-year");
footerYear.textContent = new Date().getFullYear();
